import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState } from 'react';
import styled from 'styled-components';
import SEO from '../components/SEO';
import FsLightbox from 'fslightbox-react';
import Masonry from 'react-masonry-component';

const PortfolioPage = styled.div`
  padding-top: 94px;
`;

const PortfolioPost = ({ data }) => {
  const [toggler, setToggler] = useState(false);
  const [current, setCurrent] = useState(0);

  const {
    title,
    category,
    description,
    seoMetaTags,
    content,
    imageGallery,
  } = data.project;

  return (
    <>
      <SEO meta={seoMetaTags} />
      <PortfolioPage>
        <div className="bg-orange-theme text-center mb-10 text-white py-3">
          <h1 className="text-2xl">{title}</h1>
          <h2>{description}</h2>
        </div>
        <div className="container">
          <>
            <FsLightbox
              toggler={toggler}
              sources={imageGallery.map(img => img.fluid.src)}
              sourceIndex={current}
            />
          </>
          {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
          <Masonry>
            {imageGallery.map((img, index) => (
              <button
                onClick={() => {
                  setToggler(!toggler);
                  setCurrent(index);
                }}
                key={img.originalId}
                className="w-full md:w-1/2 lg:w-1/3 p-2"
              >
                <Img fluid={img.fluid} />
              </button>
            ))}
          </Masonry>
        </div>
      </PortfolioPage>
    </>
  );
};

export const projectQuery = graphql`
  query($slug: String!) {
    project: datoCmsPortfolioPost(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      content
      description
      category {
        categoryName
      }
      imageGallery {
        originalId
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;

export default PortfolioPost;
